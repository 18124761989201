import React, { Fragment } from 'react'
import '../styles/css/cfooter.css'
import Logo from '../assets/img/logo192.png'
import DCMA from '../assets/img/dmca-badge.png'
import lightspeed from '../assets/img/google-page-speed.png'

const Footer = () => {
  return (
    <Fragment>
      <footer className="footer">
        <div className="fcontainer">
            <div className="fdet">
                <div className="fdetflex">
                    <div className="fflexsecs">
                        <div className="flogo">
                            <img src={Logo} alt="" />
                            <h3>wenotify</h3>
                        </div>
                        <div className="fsocials">
                            <i className="fa fa-facebook"></i>
                            <i className="fa fa-twitter"></i>
                            <i className="fa fa-linkedin"></i>
                            <i className="fa fa-youtube"></i>
                        </div>
                        <div className="siteProtection">
                            <img src={lightspeed} alt="Google lite speed badge" />
                            <img src={DCMA} alt="DMCA badge" />
                        </div>
                    </div>
                    <div className="fflexsecs">
                        <h2>Resources</h2>
                        <ul>
                            <li><a href="./blog.html">Blog</a></li>
                            <li><a href="./privacy.html">Privacy</a></li>
                            <li><a href="./tos.html">Terms</a></li>
                            <li><a href="./cookies.html">Cookies</a></li>
                        </ul>
                    </div>
                    <div className="fflexsecs">
                        <h2>Company</h2>
                        <ul>
                            <li><a href="./about.html">About US</a></li>
                            <li><a href="./partners.html">Our Partners</a></li>
                            <li><a href="./contact.html">Contact Us</a></li>
                            <li><a href="./careers.html">Careers</a></li>
                            <li><a href="./events.html">Events</a></li>
                        </ul>
                    </div>
                    <div className="fflexsecs">
                        <h2>Services</h2>
                        <ul>

                            <li><a href="./apis.html">APIs</a></li>
                            <li><a href="./support.html">Support</a></li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="fcopyright">
            <p>Copyright &copy; {(new Date().getFullYear())} | <a title="Developer" target="blank" href="https://denniskibet.com">wenotify Team</a> <a href="https://github.com/DennisRono" title="Github"><i className="fa-brands fa-github"></i></a></p>
        </div>
      </footer>
    </Fragment>
  )
}

export default Footer