import React, { Fragment } from 'react'
import '../styles/css/abtsec.css'
import Agile from '../assets/img/agility.png'
import { ReactComponent as AngleRight } from '../assets/svg/angleright.svg'

const About = () => {
    faq()
    function faq() {
    let faqquestion = document.querySelectorAll('.faqdroptitle')
    Array.from(faqquestion).forEach(header => {
        header.addEventListener('click', () => {
            if(!header.nextElementSibling.classList.contains('faqShow')){
            for (let i = 0; i < Array.from(faqquestion).length; i++) {
                Array.from(faqquestion)[i].nextElementSibling.classList.remove('faqShow')
                Array.from(faqquestion)[i].firstElementChild.nextElementSibling.classList.remove('angleDown')  
            }
                header.nextElementSibling.classList.toggle('faqShow')
                header.firstElementChild.nextElementSibling.classList.toggle('angleDown')
            } else {
                header.nextElementSibling.classList.remove('faqShow')
                header.firstElementChild.nextElementSibling.classList.remove('angleDown')
            }
        });
    });
    }
  return (
    <Fragment>
        <section className="about-header">
            <div className="about-header-wrapper">
                <h2>About Us</h2>
            </div>
        </section>
        <section className="mission">
            <div className="mission-wrapper">
                <div className="mission-images">
                    <div className="miss-image-wrapper">
                        <img src={Agile} alt=""/>
                    </div>
                </div>
                <div className="mission-texts">
                    <span className="sec-text">our mission</span>
                    <h1>Our mission is to build the best developer experience across the development process</h1>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo sit omnis, accusamus quos tempore sint quaerat, quas veritatis magnam repellendus distinctio nihil totam libero quis?</p>
                </div>
            </div>
        </section>
        <section className="faqmainSection">
            <div className="faqsection">
                <div className="faqcontainer">
                    <div className="faqwrapper">
                        <div className="faqflex">
                            <h1 className="faqtitle">faq</h1>
                            <div className="faqdrop">
                                <div className="faqdroptitle">
                                    <h2 className="faqText">How do you get started with nullAPI?</h2>
                                    <AngleRight className="faqangleright"/>
                                </div>
                                <div className="faqdropcontents">
                                    <p className="faqdroptexts">
                                        We are on the mission of building an interoperable and open metaverse made of thousands of virtual worlds. We strongly believe in NFTs as one of key technologies that enable interoperability. We want to give users of Ready Player Me a way to buy and access NFTs compatible with our and other platforms.
                                    </p>
                                    <p className="faqdroptexts">
                                        We share the revenue from sales of the NFTs with our partner apps and worlds, helping them monetize and build better experiences. Partners don’t need to implement blockchain tech into their products – everything is integrated directly into Ready Player Me.
                                    </p>
                                </div>
                                <div className="faqdroptitle">
                                    <h2 className="faqText">How do I get my API key for nullAPI?</h2>
                                    {/* <AngleRight className="faqangleright"/> */}
                                    <AngleRight className="faqangleright"/>
                                </div>
                                <div className="faqdropcontents">
                                    <p className="faqdroptexts">
                                        We are on the mission of building an interoperable and open metaverse made of thousands of virtual worlds. We strongly believe in NFTs as one of key technologies that enable interoperability. We want to give users of Ready Player Me a way to buy and access NFTs compatible with our and other platforms.
                                    </p>
                                    <p className="faqdroptexts">
                                        We share the revenue from sales of the NFTs with our partner apps and worlds, helping them monetize and build better experiences. Partners don’t need to implement blockchain tech into their products – everything is integrated directly into Ready Player Me.
                                    </p>
                                </div>
                                <div className="faqdroptitle">
                                    <h2 className="faqText">Which endpoints do I authenticate my requests?</h2>
                                    <AngleRight className="faqangleright"/>
                                </div>
                                <div className="faqdropcontents">
                                    <p className="faqdroptexts">
                                        We are on the mission of building an interoperable and open metaverse made of thousands of virtual worlds. We strongly believe in NFTs as one of key technologies that enable interoperability. We want to give users of Ready Player Me a way to buy and access NFTs compatible with our and other platforms.
                                    </p>
                                    <p className="faqdroptexts">
                                        We share the revenue from sales of the NFTs with our partner apps and worlds, helping them monetize and build better experiences. Partners don’t need to implement blockchain tech into their products – everything is integrated directly into Ready Player Me.
                                    </p>
                                </div>
                                <div className="faqdroptitle">
                                    <h2 className="faqText">Which authentication technique does nullAPI supports (Sessions or JWT)?</h2>
                                    <AngleRight className="faqangleright"/>
                                </div>
                                <div className="faqdropcontents">
                                    <p className="faqdroptexts">
                                        We are on the mission of building an interoperable and open metaverse made of thousands of virtual worlds. We strongly believe in NFTs as one of key technologies that enable interoperability. We want to give users of Ready Player Me a way to buy and access NFTs compatible with our and other platforms.
                                    </p>
                                    <p className="faqdroptexts">
                                        We share the revenue from sales of the NFTs with our partner apps and worlds, helping them monetize and build better experiences. Partners don’t need to implement blockchain tech into their products – everything is integrated directly into Ready Player Me.
                                    </p>
                                </div>
                                <div className="faqdroptitle">
                                    <h2 className="faqText">How do you get started with nullAPI?</h2>
                                    <AngleRight className="faqangleright"/>
                                </div>
                                <div className="faqdropcontents">
                                    <p className="faqdroptexts">
                                        We are on the mission of building an interoperable and open metaverse made of thousands of virtual worlds. We strongly believe in NFTs as one of key technologies that enable interoperability. We want to give users of Ready Player Me a way to buy and access NFTs compatible with our and other platforms.
                                    </p>
                                    <p className="faqdroptexts">
                                        We share the revenue from sales of the NFTs with our partner apps and worlds, helping them monetize and build better experiences. Partners don’t need to implement blockchain tech into their products – everything is integrated directly into Ready Player Me.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="secNewsletter" id="newsletter">
            <div className="newsletter">
                <div className="newslettercontainer">
                    <div className="subnewsletter">
                        <div className="subnewsFlex">
                            <div className="subNewstexts">
                                <h3 className="subNewstitle">Subscribe to our newsletter</h3>
                                <p className="subNewsTextsp">stay upto date with our latest blogs and news updates</p>
                            </div>
                            <div className="subnewsform">
                                <form action="" className="subNewsFormf">
                                    <div className="subinputsFlex">
                                        <input type="text" className="subnewsinput" placeholder="your e-mail address"/>
                                        <input type="submit" value="Subscribe" className="subnewsButton"/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>
  )
}

export default About