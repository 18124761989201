import React, { Fragment } from 'react'
import CFooter from '../components/CFooter'
import CHeader from '../components/CHeader'
import CReport from '../components/CReport'

const Home = () => {
  return (
    <Fragment>
        <CHeader />
        <CReport />
        <CFooter />
    </Fragment>
  )
}

export default Home