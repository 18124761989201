import React, { Fragment } from 'react'
import '../styles/css/sidebar.css'
import {ReactComponent as Home} from '../assets/svg/home.svg'
import {ReactComponent as Reports} from '../assets/svg/reports.svg'
import {ReactComponent as Chat} from '../assets/svg/chat.svg'
import { useState } from 'react'

import { useDispatch } from 'react-redux'
import { setdash, setreport, setchat } from '../actions'

const Sidebar = (props) => {
  const side = props.side
  const [tab, setTab] = useState('dash')

  const dispatch = useDispatch()

  const setTabS = (t) => {
    if(t==='dash'){
      setTab('dash')
      dispatch(setdash())
    } else if(t==='report'){
      setTab('report')
      dispatch(setreport())
    } else if(t==='chat'){
      setTab('chat')
      dispatch(setchat())
    }
  }

  return (
    <Fragment>
        <div className={(side)?"sidebar shside":"sidebar hside"}>
          <div className="sideWrapper">
            <div className="sideBrand">
              <h2>wenotify</h2>
            </div>
            <div className="sidenav">
              <ul className="unlistnav">
                <li className={(tab==='dash')?"side-link active":"side-link"} onClick={()=>setTabS('dash')}>
                  <Home className="navIcs" />
                  <span>Dashboard</span>
                </li>
                <li className={(tab==='report')?"side-link active":"side-link"} onClick={()=>setTabS('report')}>
                  <Reports className="navIcs" />
                  <span>Reports</span>
                </li>
                <li className={(tab==='chat')?"side-link active":"side-link"} onClick={()=>setTabS('chat')}>
                  <Chat className="navIcs" />
                  <span>Chat</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
    </Fragment>
  )
}

export default Sidebar