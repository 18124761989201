import React, { Fragment, useState } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import './styles/css/app.css'
import Home from './routes/Home'
import About from './routes/About'
import Admin from './routes/Admin'
import Auth from './routes/Auth'
import Reset from './routes/Reset'
import NotFound from './routes/NotFound'
import Preloader from './components/Preloader'
import useLocalStorage from 'use-local-storage'
import CookieConsent from "react-cookie-consent"
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { io } from "socket.io-client";
import { backend } from './includes/session'

const socket = io(backend(), { autoConnect: false });
const App = () => {
  //socket Authentification
  useEffect(()=>{
    let userName = JSON.parse(localStorage.getItem("user"))
    socket.auth = {userName}
    socket.connect();
  },[])
  const [isFirst, setIsfirst] = useLocalStorage('isFirst', 'yes');
  const [preloader, setPreloader] = useState(false)

  //page pre loader
  document.onreadystatechange = function() {
      if (window.location.pathname === "/" && isFirst === 'yes'){
          setIsfirst('no')
          if (document.readyState !== "complete") {
              setPreloader(true)
          } else {
              setPreloader(true)
              setTimeout(() => {
                  setPreloader(false)
              }, 6000);
          }
      } else {
          setPreloader(false)
      }
  };

  const [theme, setTheme] = useLocalStorage('theme', 'cdark');
  let currTheme = useSelector(state => state.theme)
  useEffect(()=>{
    setTheme(currTheme)
  },[currTheme, setTheme])

  return (
    <Fragment>
      <div className="App" data-theme={theme}>
          <div className={(preloader)?"preloader":"hidepreloader"}>
              <Preloader />
          </div>
        <div className={(preloader)?"hidecontents":"contents"}>
          <CookieConsent
          disableStyles={true}
          location="bottom"
          buttonText="Okay"
          cookieName="denniskibet"
          buttonClasses="button"
          containerClasses="cookieConsent"
          expires={150}
          >
              <p className="cookieText">We use cookies to improve your browsing experience.</p>
          </CookieConsent>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/admin" element={<Admin socket={socket} />} />
              <Route path="/auth" element={<Auth />} />
              <Route path="/reset" element={<Reset />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </div>
      </div>
    </Fragment>
  )
}

export default App