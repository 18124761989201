import { combineReducers } from 'redux'

const tab = (state = {tab:'dash', payload:''}, action) => {
    switch (action.type) {
        case 'SETDASH':
            return {tab:'dash', payload:action.payload}
        case 'SETREPORT':
            return {tab:'report', payload:action.payload}
        case 'SETCHAT':
            return {tab:'chat', payload:action.payload}
        default:
            return state
    }
}

const theme = (state = 'dark', action) => {
    switch (action.type) {
        case 'LIGHT':
            return 'light'
        case 'DARK':
            return 'dark'
        default:
            return state
    }
}

const allReducers = combineReducers({
    tab: tab,
    theme: theme
})

export default allReducers