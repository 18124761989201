import React, { Fragment, useState } from 'react'
import '../styles/css/creports.css'
import { ReactComponent as Upload } from '../assets/svg/icon-download.svg'
import axios from 'axios'
import { backend } from '../includes/session'

const Report = () => {
    const [data, setData] = useState({
        name: '',
        email: '',
        phone: '',
        location: '',
        brief: '',
        assets: null
    })

    const [btntxt, setBtntxt] = useState('report')
    const [response, setResponse] = useState({message: '', type: ''})
    const handleSubmit =  async (e) => {
        e.preventDefault()
        setBtntxt('sending...')
        const formData = new FormData()
        if(data.assets){
            Object.values(data.assets).forEach(file=>{
                formData.append("assets", file)
            })
        }
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                formData.append(key, data[key])
            }
        }
        let config = {
            method: 'post',
            url: backend()+'/report',
            headers: { 
              'auth-token': 'eyJhbGciOiJIUzI1NiJ9.a3l5NXhocnQ4OTQ3d205N25ucA.jZUx505N8EqxuaYZNeqrIGDfJVLd0vCEFfhXknrn3M8',
            },
            data : formData
        };
        const res = await axios(config)
        setResponse(res.data)
        setBtntxt('report') 
        setData({
            name: '',
            email: '',
            phone: '',
            location: '',
            brief: '',
            assets: null
        })
        getFile(true)
        setTimeout(() => {
            setResponse({message: '', type: ''})
        }, 5000)
    }
    const getFile = (c) => {
        const files = document.querySelector('.file-field').files
        let label = document.querySelector('.content-label')
        let finlabel = ""
        let filenames = []
        for (var i = 0; i < files.length; ++i) {
            var name = files.item(i).name;
            filenames.push(name)
        }
        filenames.map(i=>{
            return (
            finlabel = finlabel + "<span class='filenames-list'>"+i+"</span>"
            )
        })
        if(c){
            label.innerHTML = 'Drag or browse your project brief (you can upload multipe files)'
        } else {
            label.innerHTML = finlabel
        }
    }
  return (
    <Fragment>
        <section className="ccontact">
            <div className="contact-wrapper">
                <div className="contFormSec">
                    <h2 className="contact-header">Report Now</h2>
                    {(response.message!=='')?<p className={(response.type==='success')?"formNotifySucc":"formNotify"}>{response.message}</p>:null}
                    <form method="POST" onSubmit={e=>handleSubmit(e)}>
                        <h3>1. Tell us about yourself (optional)</h3>
                        <div className="cont-group">
                            <div className="user-input-wrp">
                                <br/>
                                <input id="id-input" type="text"  className="inputText" name="name" value={data.name} onChange={e=>{setData({ ...data, [e.target.name]: e.target.value })}} required/>
                                <span className="floating-label">Your Name </span>
                            </div>
                            <span id="id-err"></span>
                            <div className="cont-gr-flex">
                                <div className="contPut">
                                    <div className="user-input-wrp">
                                        <br/>
                                        <input id="id-input" type="text"  className="inputText" name="email" value={data.email} onChange={e=>{setData({ ...data, [e.target.name]: e.target.value })}} required/>
                                        <span className="floating-label">Your Email </span>
                                    </div>
                                    <span id="id-err"></span>
                                </div>
                                <div className="contPut">
                                    <div className="user-input-wrp">
                                        <br/>
                                        <input id="id-input" type="text"  className="inputText" name="phone" value={data.phone} onChange={e=>{setData({ ...data, [e.target.name]: e.target.value })}} required/>
                                        <span className="floating-label">Your Phone Number</span>
                                    </div>
                                    <span id="id-err"></span>
                                </div>
                            </div>
                        </div>
                        
                        <h3>2. Please describe the crime you are reporting?</h3>
                        <div className="cont-group">
                            <div className="user-input-wrp">
                                <br/>
                                <input id="id-input" type="text"  className="inputText" name="location" value={data.location} onChange={e=>{setData({ ...data, [e.target.name]: e.target.value })}} required/>
                                <span className="floating-label">Crime Location <span style={{color: "red"}}>*</span></span>
                            </div>
                            <span id="id-err"></span>
                            <div className="user-input-wrp">
                                <br/>
                                <textarea id="id-input" type="text"  className="inputText" name="brief" value={data.brief} onChange={e=>{setData({ ...data, [e.target.name]: e.target.value })}} style={{minHeight: "100px"}} required></textarea>
                                <span className="floating-label">Describe the crime <span style={{color: "red"}}>*</span></span>
                            </div>
                            <span id="id-err"></span>
                            <div className="input-field-row">
                            <div className="input-field-row">
                                <div className="input-file-row">
                                    <label htmlFor="project_brief" className="project_brief">
                                        <div className="file-content">
                                            <div className="icon">
                                                <Upload className="image-upload-icon"/>
                                            </div>
                                            <div className="content-label">
                                                Drag or browse your project brief (you can upload multipe files)
                                            </div>
                                        </div>
                                        <div className="row-wrap">
                                            <div id="filePush" className="button">upload</div>
                                        </div>
                                    </label>
                                    <input
                                        className="file-field"
                                        type="file"
                                        name="assets"
                                        onChange={e=>{
                                            setData({ ...data, [e.target.name]: e.target.files })
                                            getFile(false)
                                        }}
                                        multiple="multiple"
                                    />
                                </div>
                                <span id="id-err fileErr"></span>
                            </div>
                                <span id="id-err fileErr"></span>
                            </div>
                        </div>
                        <input type="submit" value={btntxt} name="contact-page" className="contact-btn"/>
                    </form>
                </div>
            </div>
        </section>
    </Fragment>
  )
}

export default Report