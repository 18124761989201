export const setdash = () => {
    return {
        type: 'SETDASH',
        payload: ''
    }
}

export const setreport = () => {
    return {
        type: 'SETREPORT',
        payload: ''
    }
}

export const setonereport = (id) => {
    return {
        type: 'SETREPORT',
        payload: id
    }
}

export const setchat = () => {
    return {
        type: 'SETCHAT',
        payload: ''
    }
}

export const setdark = () => {
    return {
        type: 'DARK',
        payload: ''
    }
}

export const setlight = () => {
    return {
        type: 'LIGHT',
        payload: ''
    }
}