import React, { Fragment } from 'react'
import '../styles/css/dashboard.css'
import {ReactComponent as Profile} from '../assets/svg/user.svg'
import {ReactComponent as Home} from '../assets/svg/home.svg'
import {ReactComponent as Rate} from '../assets/svg/rate.svg'
import {ReactComponent as Flag} from '../assets/svg/flag.svg'
import {ReactComponent as Shield} from '../assets/svg/shield.svg'
import {ReactComponent as RectangleList} from '../assets/svg/rectangle-list.svg'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2'
import { useNavigate } from 'react-router-dom'
import { setJwtToken, setRefreshToken } from '../includes/session'

const Dashboard = () => {
  //logout
  let navigate = useNavigate()
  const logout = () => {
    setJwtToken('')
    setRefreshToken('')
    return navigate("/auth")
  }
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Crime Rate in Kenya',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Years'
        }
      },
      y: {
        title: {
          display: true,
          text: 'crime rate per 100K population'
        }
      }
    },
  }

  const labels = ['2004','2005','2006','2007','2008','2009','2010','2011','2012','2014','2015','2016','2017','2018','2019','2020'];
  const data = {
    labels,
    datasets: [
      {
        label: 'crime rate',
        data: [3.3666,2.9586,2.9376,2.8462,3.0538,4.6636,4.5824,5.2596,5.3537,4.9358,4.7224,4.8174,4.9103,4.9287,2.9977,3.4572],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        tension: 0.1
      }
    ],
  };
  return (
    <Fragment>
        <div className="dashboard">
          <div className="topOne">
            <div className="wetopleft">
              <div className="adminwelcome">
                <div className="welRtexts">
                  <h2>Hi, welcome back <br/>Dennis!</h2>
                  <p>view crime reports and take actions <br/>
                  You can also view usage statictics from this admin panel</p>
                  <button className="adminLogout" onClick={()=>{logout()}}>logout</button>
                </div>
                <div className="welProfilePic">
                  <div className="adminProfile">
                    <Profile className="addPr"/>
                  </div>
                </div>
              </div>
              <div className="wetopbtm">
                <div className="wetbtone">
                  <div className="tbrTexts">
                    <p>Crime Rate</p>
                    <h3>5,142</h3>
                    <div className="lweekstats">
                      <p>Last Week</p>
                      <span></span>
                      <p>+427</p>
                    </div>
                  </div>
                  <div className="tbrIcon">
                    <Rate className="MainIctbr"/>
                  </div>
                </div>
                <div className="wetbttwo">
                  <div className="tbrTexts">
                    <p>Total Reports</p>
                    <h3>20, 000</h3>
                    <div className="lweekstats">
                      <p>Last Week</p>
                      <span></span>
                      <p>+572</p>
                    </div>
                  </div>
                  <div className="tbrIcon">
                    <Flag className="MainIctbr"/>
                  </div>
                </div>
              </div>
              <div className="wetopbtm">
                <div className="wetbtone">
                  <div className="tbrTexts">
                    <p>Dispatched Reports</p>
                    <h3>20, 000</h3>
                    <div className="lweekstats">
                      <p>Last Week</p>
                      <span></span>
                      <p>+572</p>
                    </div>
                  </div>
                  <div className="tbrIcon">
                    <Shield className="MainIctbr"/>
                  </div>
                </div>
                <div className="wetbttwo">
                  <div className="tbrTexts">
                    <p>Pending Reports</p>
                    <h3>20, 000</h3>
                    <div className="lweekstats">
                      <p>Last Week</p>
                      <span></span>
                      <p>+572</p>
                    </div>
                  </div>
                  <div className="tbrIcon">
                    <RectangleList className="MainIctbr"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="crimeGraphic">
              <Line options={options} data={data} />
            </div>
          </div>
          <div className="toptwo">
            <div className="ttwoleft">
              <h3>Recent Crime Zones</h3>
            </div>
            <div className="ttworight">
              <div className="activeadmins">
                <h4>active admins</h4>
              </div>
              <div className="activeclients">
                <h4>active clients</h4>
              </div>
            </div>
          </div>
        </div>
    </Fragment>
  )
}

export default Dashboard