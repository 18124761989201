import React, { Fragment } from 'react'
import CHeader from '../components/CHeader'
import CAbout from '../components/CAbout'
import CFooter from '../components/CFooter'

const About = () => {
  return (
    <Fragment>
        <CHeader/>
        <CAbout />
        <CFooter/>
    </Fragment>
  )
}

export default About