import React, { Fragment, useState, useEffect, useRef } from 'react'
import '../styles/css/header.css'
import Sidebar from './Sidebar'
import {ReactComponent as Lens} from '../assets/svg/magnifyingGlass.svg'
import {ReactComponent as ArrRight} from '../assets/svg/arrowright.svg'
import {ReactComponent as ArrLeft} from '../assets/svg/arrowleft.svg'
import {ReactComponent as Message} from '../assets/svg/message.svg'
import {ReactComponent as Bell} from '../assets/svg/bell.svg'
import {ReactComponent as Profile} from '../assets/svg/user.svg'
import Dashboard from '../components/Dashboard'
import Reports from '../components/Reports'
import Chat from '../components/Chat'
import {ReactComponent as Sun} from '../assets/svg/sun.svg'
import {ReactComponent as Moon} from '../assets/svg/moon.svg'
import { useSelector } from 'react-redux'
import useLocalStorage from 'use-local-storage'
import NotificationSound from "../assets/sound/notification_sound.wav";

import { useDispatch } from 'react-redux'
import { setdark, setlight, setonereport } from '../actions'

const Header = ({socket}) => {
  const [side, setSide] = useState(true)
  const sideControl = () => {
    if(side){
      setSide(false)
    } else {
      setSide(true)
    }
  }

  const dispatch = useDispatch()

  const setAcTheme = (t) => {
    if(t==='dark'){
      dispatch(setdark())
    } else {
      dispatch(setlight())
    }
  }
  const activetab = useSelector(state => state.tab)
  let activetheme = useSelector(state => state.theme)

  // notification socket
  const [messlist, setMesslist] = useLocalStorage('messages', []);
  const [notlist, setNotList] = useLocalStorage('notifications', []);
  const [notification, setNotification] = useState({notification:false})
  const [hidedropnotif, sethidedropnotif] = useState(false)
  const toggleNofit = () => {
    if(hidedropnotif) {sethidedropnotif(false)} else {sethidedropnotif(true)}
  }
  const notifEvents = (reportid) => {
    dispatch(setonereport(reportid))
    setNotList(notlist.filter((item) => item.data.reportid !== reportid));
  }
  //audio play
  const audioPlayer = useRef(null);
  useEffect(()=> {
      socket.on("notification", data => {
        setNotification(data)
        setMesslist([])
        if(audioPlayer!=='null'){
          audioPlayer.current.play();
        }
      })
      if(notification.notification) {
        setNotList([...new Set([...notlist, notification].map(o => JSON.stringify(o)))].map(s => JSON.parse(s)))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket, notification])
  return (
    <Fragment>
      <audio ref={audioPlayer} src={NotificationSound} />
        <header className="header">
          <div className="container">
            <div className="hFlex">
              <div className="sidebarr">
                <Sidebar side={side} />
              </div>

              <div className="contarea">
                <div className="nav">
                  <div className="left">
                    <div className="navControls" onClick={()=>sideControl()}>
                      {(side)?<ArrLeft className="navBars"/>:<ArrRight className="navBars"/>}
                    </div>
                    <form className="searchForm">
                      <input type="text" placeholder="search..." className="searchInput"/>
                      <button className="searchformbtn" type="submit"><img src={Lens} alt="" className="searchLens" /></button>
                    </form>
                  </div>
                  <div className="right">
                    <div className="themeControls">
                      {(activetheme==='light')?<Moon alt="" className="thmoon" onClick={()=>{setAcTheme('dark')}}/>:<Sun className="thsun" onClick={()=>{setAcTheme('light')}}/>}
                    </div>
                    <div className="notifwrapper">
                      <Message className="msgIc"/>
                      <div className={(messlist.length!==0)?"notfBars":"notfBars hidenofifBars"}><div><span>{notlist.length}</span></div></div>
                    </div>
                    <div className="notifwrapper" onClick={()=>{toggleNofit()}}>
                      <Bell className="bellIc" />
                      <div className={(notlist.length!==0)?"notfBars":"notfBars hidenofifBars"}><div><span>{notlist.length}</span></div></div>
                    </div>
                    <div className="havatar">
                      <Profile className="avatarHolder" />
                    </div>
                  </div>

                </div>
                {/* // dropdown menu */}
                <div className={(hidedropnotif)?"navDropDown":"navDropDown hidenavDropdown"}>
                  <ul>
                    {(notlist.length!==0)?
                      notlist.map(not=>{
                        if(not.notification){
                          return (
                            <li onClick={()=>{notifEvents(not.data.reportid)}} key={not.data.reportid}>
                              <h2>{not.data.location}</h2>
                              <p>{not.data.brief}</p>
                            </li>
                          )
                        } else {
                          return <div></div>
                        }
                      })
                    :null}
                  </ul>
                </div>

                <div className="playarea">
                  {(activetab.tab==='dash')?<Dashboard/>:(activetab.tab==='report')?<Reports/>:(activetab.tab==='chat')?<Chat socket={socket}/>:<Dashboard/>}
                </div>
              </div>
            </div>
          </div>
        </header>
    </Fragment>
  )
}

export default Header