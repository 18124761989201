import React, { Fragment, useState } from 'react'
import '../styles/css/reports.css'
import {ReactComponent as ArrLeft} from '../assets/svg/arrowleft.svg'
import {ReactComponent as Download} from '../assets/svg/download.svg'
import {ReactComponent as Trash} from '../assets/svg/trash.svg'
import {ReactComponent as Inbox} from '../assets/svg/inbox.svg'
import { api } from '../api/axios'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { setonereport } from '../actions'
import { backend } from '../includes/session'

const Reports = () => {
  const [reports, setReports] = useState({data:[]})
  const [report, setReport] = useState({})
  let backendUrl = backend()+'/'
  const getreports = async () => {
    let res = await api('GET', 'getreports', '')
    setReports(res)
  }
  useEffect(()=>{
    getreports()
  },[])
  const [rmod, setRmod] = useState(true)
  const hideRepMod = (t) => {
    (t==='show')?setRmod(false):setRmod(true)
  }

  const getonereport = (id) => {
    let rr = reports.data.find(o => o.RepID === id)
    setReport(rr)
  }

  const dispatch = useDispatch()
  const activereport = useSelector(state => state.tab)
  useEffect(()=>{
    let payloadnotif = activereport.payload
    if(payloadnotif!==''){
      let rr = reports.data.find(o => o.RepID === payloadnotif)
      console.log(rr);
      if(typeof rr !== 'undefined'){
        if (Object.keys(rr).length !== 0) {
          setRmod(false)
          setReport(rr)
        }
      }
      dispatch(setonereport(''))
    }
  },[activereport, dispatch, reports.data])

  const downloadonerep = async (id) => {
    let rr = reports.data.find(o => o.RepID === id)
    setReport(rr)
    let res = await api('POST', 'downloadonerep', report)
    console.log(`${backendUrl}data/${res.file}`);
    if(res.state==='success'){
      const link = document.createElement('a');
      link.href = `${backendUrl}data/${res.file}`;
      document.body.appendChild(link);
      setTimeout(() => {
        link.click();
        document.body.removeChild(link);        
      }, 1000);
    }
  }
  const downloadallreports = async () => {
    let res = await api('POST', 'downloadallreports', reports)
    if(res.state==='success'){
      const link = document.createElement('a');
      link.href = `${backendUrl}data/${res.file}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const deletereport = async (id) => {
    console.log(id);
    let res = await api('POST', 'deletereport', {id:id})
    if(res.state==='success'){
      console.log(res.message);
      getreports()
    }
  }

  let samples = []
  if(typeof report !== 'undefined'){
    if (Object.keys(report).length !== 0) {
      if(typeof report.Uploads !== 'undefined'){
        let y = report.Uploads.substring(1).split('#')
        for (let i = 0; i < y.length; i++) {
            samples.push(y[i])
        }
      }
    }
  }


  document.addEventListener('animationstart', function (e) {
    if (e.animationName === 'fadeOut') {
        e.target.classList.remove('hide');
    }
  });
  
  document.addEventListener('animationend', function (e) {
    if (e.animationName === 'fadeOut') {
        e.target.classList.add('hide');
     }
  });
  return (
    <Fragment>
        <div className="reports">
          <div className="rWrapper">
            <div className="section-title">
              <h1>Crime Reports</h1>
              <div className="sec-events">
                <button className="dwld-table" onClick={()=>{downloadallreports()}}>download</button>
                <div className="rows-per-page"></div>
              </div>
            </div>
            
            <div className="table-wrap">
              <table>
                <thead>
                  <tr>
                    <td>ID</td>
                    <td>Name</td>
                    <td>Email</td>
                    <td>Phone</td>
                    <td>Location</td>
                    <td>Brief</td>
                    <td>Date & Time</td>
                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {reports.data.map(item=> {
                    let repid = item.RepID
                    return (
                      <tr key={item.ID}>
                        <td onClick={()=>{hideRepMod('show'); getonereport(repid); window.scrollTo(0, 0);}}>{item.ID}</td>
                        <td style={{whiteSpace: "nowrap"}} onClick={()=>{hideRepMod('show'); getonereport(repid); window.scrollTo(0, 0);}}>{item.Name}</td>
                        <td onClick={()=>{hideRepMod('show'); getonereport(repid); window.scrollTo(0, 0);}}>{item.Email}</td>
                        <td onClick={()=>{hideRepMod('show'); getonereport(repid); window.scrollTo(0, 0);}}>{item.Phone}</td>
                        <td onClick={()=>{hideRepMod('show'); getonereport(repid); window.scrollTo(0, 0);}}>{item.Location}</td>
                        <td onClick={()=>{hideRepMod('show'); getonereport(repid); window.scrollTo(0, 0);}}><span>{item.Brief}</span></td>
                        <td onClick={()=>{hideRepMod('show'); getonereport(repid); window.scrollTo(0, 0);}} style={{whiteSpace: "nowrap"}}>{item.TimeStamp}</td>
                        <td>
                          <div className="actions-wrapp">
                            <Download className="report-action" onClick={()=>{downloadonerep(repid)}}/>
                            <Trash className="report-action" onClick={()=>{deletereport(repid)}}/>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
          
          {(reports.data.length===0)?
          <div className="noreportsmodal">
            <div className="norepmainwrapper">
              <div className="noreportswrap">
                <Inbox className="inboxsvgicon" />
                <h2>no reports</h2>
                <p>it's safe out there</p>
              </div>
            </div>
          </div>:null}

          <div className={(rmod)?"reportModal hiderepmod":"reportModal showrepmod"}>
            <div className="rmodalWrapper">
              <ArrLeft className="arrleft" onClick={()=>{hideRepMod('hide')}}/>
              <h2>Incident at {report.Location}</h2>
              <div className="reporterdetails">
                <h4>{report.Name}</h4>
                <h4>{report.Email}</h4>
                <h4>{report.TimeStamp}</h4>
              </div>
              <p>{report.Brief}</p>
              <div className="reportImages">
                {(samples.length!==0)?<h2>Samples</h2>:null}                
                <div className="samwrapper">
                  {samples.map(item=> {
                    return (
                    <div className="assetShow" key={item}>
                      <img src={backendUrl+'uploads/'+item} alt="" />
                    </div>
                    )
                  })}
                </div>
              </div>
              <button className="repoAction">Dispatch Police</button>
            </div>
          </div>
        </div>
    </Fragment>
  )
}

export default Reports