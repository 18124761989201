import axios from "axios"
import { backend } from '../includes/session'

export const api = async (method = 'GET', slug = '', data = {}) => {
    let config = {
        method: method,
        url: backend()+'/'+slug,
        headers: { 
            'Content-Type': 'application/json'
        },
        data : JSON.stringify(data)
    };
    const response = await axios(config)
    return response.data
}