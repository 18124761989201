import React, { Fragment, useState, useEffect, useRef } from "react";
import "../styles/css/chat.css";
import dp from "../assets/img/agility.png";
import { ReactComponent as Mic } from "../assets/svg/microphone.svg";
import { ReactComponent as Send } from "../assets/svg/paper-plane.svg";
import { ReactComponent as Smiley } from "../assets/svg/smile-face.svg";
import { ReactComponent as Mail } from "../assets/svg/mail.svg";
import Profile from "../assets/img/agility.png";
import Picker from 'emoji-picker-react'
import { api } from '../api/axios'
import NotificationSound from "../assets/sound/incoming.mp3";
import { useDebounce } from 'use-debounce'

const Chat = ({socket}) => {
    const [acCat, setAcCat] = useState("admins");
    const [nmess, setNmess] = useState({
      message: ''
    })
    const onEmojiClick = (event, emojiObject) => {
      setNmess({ ...nmess, message: nmess.message+emojiObject.emoji })
    }
    const [drawer, setDrawer] = useState(false)

    const currTime = (zone) => {
      return new Date().toLocaleString('en-US', { timeZone: zone })
    }
    let userName = JSON.parse(localStorage.getItem("user"))

    //audio play
    const audioPlayer = useRef(null);
    
    //sockets
    const [selecteduser, setSelecteduser] = useState([])
    const [messages, setMessages] = useState([])
    const [ChatList, setChatList] = useState([])
    const [onlineusers, setOnlineusers] = useState([])
    const [isonline, setIsonline] = useState(false)
    const [typingStatus, setTypingStatus] = useState((isonline)?'oline':'offline')
    const [debnmess] = useDebounce(nmess.message, 1000)

    //online users
    useEffect(()=>{
      socket.on('onlineusers', data=>{
        setOnlineusers(data)
      })
    },[onlineusers, socket])

    const getchatlist = async () => {
      const res = await api('POST', 'chat', userName)
      setChatList(res)
    }
    //get chatid and prevchats
    useEffect(()=>{
      getchatlist()
    },[])
    //fetch selected chats
    const fetchSelectedChat = async (selected) => {
      const res = await api('POST', 'chat/onechat', {sender:userName, receiver:selected})
      setMessages(res.reverse())
    }

    //private message
    useEffect(()=>{
      socket.on('private message', data=>{
        setMessages([...messages, data])
        if(data.Sender!==userName.email){
          if(audioPlayer!=='null'){
            audioPlayer.current.play();
          }
        }
      })
      document.querySelector('.playmessages').scrollTop = document.querySelector('.playmessages').scrollHeight;
    },[messages, socket])

    const handleNewMess = (e) => {
      e.preventDefault()
      if(nmess.message.trim() && userName) {
        let msend = {
          Sender: userName.email,
          Receiver: selecteduser.Email,
          Message: nmess.message,
          Notification: "0",
          PicturePath: "",
          TimeStamp: currTime('Africa/Nairobi'),
          isAdmin: "yes",
          isPic: "0",
          qued: 0
        }
        socket.emit("private message", msend)
      }
      setNmess({message: ''})
    }
    // handle user typing and last seen
    useEffect(()=>{
        socket.emit('typing', {email: selecteduser.Email,state:'typing...'})
        if (nmess.message===debnmess) {
            socket.emit('typing', {email: selecteduser.Email,state:'online'})        
          }
        },[debnmess, nmess.message, selecteduser.Email, socket])
        
        useEffect(()=> {
          socket.on("typingResponse", data => {
            setTypingStatus(data.state)
          })
        }, [debnmess, nmess.message, socket])
    const messagesEndRef = useRef(null)
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ 
        block: "nearest",
        inline: "center",
        behavior: "smooth",
        alignToTop: false
      })
    }

    useEffect(() => {
      scrollToBottom()
    }, [messages]);
    useEffect(()=>{
      setIsonline(typeof [...new Set(onlineusers.map(o => JSON.stringify(o)))].map(s => JSON.parse(s)).find(x => x.username.email === selecteduser.Email)!=='undefined')
    },[onlineusers, selecteduser])

    
    return (
      <Fragment>
        <audio ref={audioPlayer} src={NotificationSound} />
        <div className="chatsection">
          <div className="thechat">
            <h2>Chat</h2>
            <div className="chatwrapper">
              <div className={(selecteduser.Email)?"usrlist":"usrlist prflexhalf"}>
                <div className="chatcategory">
                  <button
                    className={acCat === "admins" ? "catbtn" : "inactivecatbtn"}
                    onClick={() => {
                      setAcCat("admins");
                    }}
                  >
                    admins
                  </button>
                  <button
                    className={acCat === "clients" ? "catbtn" : "inactivecatbtn"}
                    onClick={() => {
                      setAcCat("clients");
                    }}
                  >
                    clients
                  </button>
                </div>
                <ul className="chatunlists">
                  {(ChatList.chatted !== undefined && ChatList.chatted !== null)?ChatList.chatted.map(item => {
                    return(
                      <li className="chatone" key={item.UserID} onClick={()=>{
                        setSelecteduser(item);
                        fetchSelectedChat(item);
                        }}>
                        <div className="chldp">
                          <div className="chldppd">
                            <img src={dp} alt="" />
                          </div>
                          {(isonline)?<div className="chldonline"></div>:null}
                        </div>
                        <div className="chldinfo">
                          <div className="chldmeta">
                            <h4 className="chldmetaname">{item.FirstName+" "+item.LastName}</h4>
                            <span className="chldmetalsttime">{new Date(item.TimeStamp).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</span>
                          </div>
                          <div className="chldpreview">
                            <p>{item.preview}</p>
                          </div>
                        </div>
                      </li>
                    )
                  }):null}
                  {(ChatList.notchatted !== undefined && ChatList.notchatted !== null)?ChatList.notchatted.map(item => {
                    return(
                      <li className="chatone" key={item.UserID} onClick={()=>{setSelecteduser(item); fetchSelectedChat(item);}}>
                        <div className="chldp">
                          <div className="chldppd">
                            <img src={dp} alt="" />
                          </div>
                          {(isonline)?<div className="chldonline"></div>:null}
                        </div>
                        <div className="chldinfo">
                          <div className="chldmeta">
                            <h4 className="chldmetaname">{item.FirstName+" "+item.LastName}</h4>
                            <span className="chldmetalsttime"></span>
                          </div>
                        </div>
                      </li>
                    )
                  }):null}
                </ul>
              </div>
              <div className={(selecteduser.Email)?"chatbox":"chatbox widthzero"}>
                <div className="chatheader">
                  <div className="chleft">
                    <div className="chldp">
                      <div className="chldppd">
                        <img src={dp} alt="" />
                      </div>
                      <div className="chldonline"></div>
                    </div>
                    <div className="chltexts">
                      <h3>{selecteduser.FirstName+" "+selecteduser.LastName}</h3>
                      <span>{typingStatus}</span>
                    </div>
                  </div>
                  <div className="chright"></div>
                </div>
                <div className="chatplay">
                  <div className="playmessages" >
                    <div ref={messagesEndRef} />
                    <ol className="chat">
                      <div className="messecurity">
                        <p>
                          Messages are end-to-end encrypted. No one outside of this
                          chat, not even us, can read or listen to them. Click to
                          learn more.
                        </p>
                      </div>
                    {/* .filter(item=>item.to===selecteduser.Email) */}
                      {/* eslint-disable-next-line  */}
                      {messages.filter(i=>i.Sender===selecteduser.Email||i.Receiver===selecteduser.Email).map(item => {
                        if(item.Sender!==userName.email){
                          return(
                            <li className="other" key={(new Date()).getTime().toString(36) + Math.random().toString(36).slice(2)}>
                              <div className="msg">
                                {/* <span className="range admin">Admin</span> */}
                                <p>{item.Message}</p>
                                <time>{new Date(item.TimeStamp).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</time>
                              </div>
                            </li>
                          )
                        } else if (item.Sender===userName.email){
                          return(
                            <li className="self" key={(new Date()).getTime().toString(36) + Math.random().toString(36).slice(2)}>
                              <div className="msg">
                                <p>{item.Message}</p>
                                {(item.isPic)?<img src={item.PicturePath} alt="" />:null}
                                <time>{new Date(item.TimeStamp).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</time>
                              </div>
                            </li>
                          )
                        } else if(item.notification){
                          return(
                            <p className="notification" key={item.id}>today</p>
                          )
                        }
                      })}
                      
                    </ol>
                  </div>
                  <div className={(drawer)?"emojidrawer":"emojidrawer hideemojidrawer"}>
                    <Picker onEmojiClick={onEmojiClick} className="emojipicker" width="100%"/>
                  </div>
                </div>
                <div className="chatbar">
                  <div className="typebar">
                    <form className="chatform" onSubmit={(e)=>{handleNewMess(e)}}>
                      <div className="iccs">
                        <Smiley className="smileyface" onClick={()=>{setDrawer(!drawer)}} />
                      </div>
                      <textarea
                        className="chatinput"
                        type="text"
                        placeholder="Type a message"
                        name="message"
                        value={nmess.message}
                        onChange={(e)=>{
                          setNmess({ ...nmess, [e.target.name]: e.target.value })
                        }}
                      ></textarea>
                      <div className="sendsec">
                        <button type="submit">
                          {(nmess.message==='')?<Mic className="apticon" />:<Send className="apticon" />}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className={(selecteduser.Email)?"mypinfo":"mypinfo prflexhalf"}>
                <div className="ipinfodp">
                  <div className="ipinfoprofile">
                    <img src={Profile} alt="" />
                  </div>
                  <div className="ipinfoprofiledet">
                    <h3 className="ipname">Dennis Kibet</h3>
                    <p className="iplevel">Level 1</p>
                  </div>
                </div>
                <div className="ipinfodetails">
                  <h2>Contact Details:</h2>
                  <div className="detipone">
                    <div className="dettip">
                      <Mail className="dettic" />
                      <div>
                        <p>Email</p>
                        <p>dennis@gmail.com</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
};

export default Chat;
